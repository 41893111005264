import { useAppSelector } from '../../../store/hooks';
import { SiteFeature } from '../../../models/SiteFeature';
import {
  selectCurrentGouletZone,
  selectSites,
  selectToggleConsommation,
} from '../../../store/mainReducer';
import { HeatmapLayer } from '@deck.gl/aggregation-layers/typed';
import useDeviceDetect from '../../../utils/useDeviceDetect';

const useConsoLayer = (): Array<HeatmapLayer<SiteFeature>> => {
  const active = useAppSelector(selectToggleConsommation);
  const sites = useAppSelector(selectSites);
  const currentGouletZone = useAppSelector(selectCurrentGouletZone);

  const isMobile = useDeviceDetect();

  const layer = new HeatmapLayer<SiteFeature>({
    id: 'HeatmapLayer',
    data: sites.filter(item => item.properties.TOTAL_CONSO > 0),
    // threshold: 1,
    aggregation: 'SUM',
    getPosition: (d: SiteFeature) => d.position as any,
    getWeight: (d: SiteFeature) => d.properties.TOTAL_CONSO,
    colorRange: [
      [34, 27, 54, 255],
      [58, 30, 73, 255],
      [82, 29, 92, 255],
      [106, 27, 95, 255],
      [128, 28, 91, 255],
    ],
    intensity: 20,
    radiusPixels: isMobile ? 5 : 40,
    threshold: 0.1,
    visible: active && currentGouletZone === '',
    pickable: false,
    weightsTextureSize: 512,
    debounceTimeout: 50000000,
  });

  return [layer];
};

export default useConsoLayer;
