import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import LeftAreaComponent from '../left-area/index';
import RightAreaComponent from '../right-area';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { selectModalLegend, toggleModalLegend } from '../../../store/mainReducer';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    overflow-y: scroll;
    max-height: 35rem;
  }
`;

const ResponsiveBtnsComponent = () => {
  const selectedModel = useAppSelector(selectModalLegend);
  const dispatch = useAppDispatch();

  const [showRight, setShowRight] = useState(false);

  const handleCloseLeft = () => dispatch(toggleModalLegend(false));
  const handleShowLeft = () => dispatch(toggleModalLegend(true));

  const handleCloseRight = () => setShowRight(false);
  const handleShowRight = () => setShowRight(true);

  return (
    <div className="p-2">
      {/* Toggle buttons (visible only on small screens) */}
      <div className="row">
        <div className="col-12 d-flex flex-column">
          <Button
            className="btn-cf2 mb-2"
            variant="secondary"
            onClick={handleShowLeft}
          >
            {'Paramètres de la simulation'}
          </Button>
          <Button
            className="btn-cf2"
            variant="secondary"
            onClick={handleShowRight}
          >
            {'Rapport de la simulation'}
          </Button>
        </div>
      </div>

      {/* Left Sidebar Modal */}
      <StyledModal show={selectedModel} onHide={handleCloseLeft}>
        <Modal.Header closeButton>
          <Modal.Title>{'Paramètres de la simulation'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-body">
          {/* Left sidebar content */}
          <LeftAreaComponent />
        </Modal.Body>
      </StyledModal>

      {/* Right Sidebar Modal */}
      <Modal show={showRight} onHide={handleCloseRight}>
        <Modal.Header closeButton>
          <Modal.Title>{'Rapport de la simulation'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Right sidebar content */}
          <RightAreaComponent />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ResponsiveBtnsComponent;
