import { IconLayer } from '@deck.gl/layers/typed';

import {
  selectCurrentGouletZone,
  selectCurrentYear,
  selectGouletSymbols,
  selectStateClimate,
} from '../../../store/mainReducer';
import { useAppSelector } from '../../../store/hooks';
import { GouletSymbolFeature } from '../../../models/GouletSymbolFeature';
import { splitCategory, splitYear } from '../../../utils';

import B_2030_EJCS_conso_1 from '../../../assets/images/symbols/B_2030_EJCS_conso_1.svg';
import B_2030_EJCS_conso_2 from '../../../assets/images/symbols/B_2030_EJCS_conso_2.svg';
import B_2030_HNVC_conso_1 from '../../../assets/images/symbols/B_2030_HNVC_conso_1.svg';
import B_2030_HNVC_conso_2 from '../../../assets/images/symbols/B_2030_HNVC_conso_2.svg';

import B_2035_EJCS_conso_1 from '../../../assets/images/symbols/B_2035_EJCS_conso_1.svg';
import B_2035_EJCS_conso_2 from '../../../assets/images/symbols/B_2035_EJCS_conso_2.svg';
import B_2035_HNVC_conso_1 from '../../../assets/images/symbols/B_2035_HNVC_conso_1.svg';
import B_2035_HNVC_conso_2 from '../../../assets/images/symbols/B_2035_HNVC_conso_2.svg';

import B_2040_EJCS_conso_1 from '../../../assets/images/symbols/B_2035_EJCS_conso_1.svg';
import B_2040_EJCS_conso_2 from '../../../assets/images/symbols/B_2035_EJCS_conso_2.svg';
import B_2040_HNVC_conso_1 from '../../../assets/images/symbols/B_2035_HNVC_conso_1.svg';
import B_2040_HNVC_conso_2 from '../../../assets/images/symbols/B_2035_HNVC_conso_2.svg';

import C_2030_EJCS_conso_1 from '../../../assets/images/symbols/C_2030_EJCS_conso_1.svg';
import C_2030_EJCS_conso_2 from '../../../assets/images/symbols/C_2030_EJCS_conso_2.svg';
import C_2030_EJCS_conso_3 from '../../../assets/images/symbols/C_2030_EJCS_conso_3.svg';

import C_2035_EJCS_conso_1 from '../../../assets/images/symbols/C_2035_EJCS_conso_1.svg';
import C_2035_EJCS_conso_2 from '../../../assets/images/symbols/C_2035_EJCS_conso_2.svg';
import C_2035_EJCS_conso_3 from '../../../assets/images/symbols/C_2035_EJCS_conso_3.svg';

import C_2040_EJCS_conso_1 from '../../../assets/images/symbols/C_2040_EJCS_conso_1.svg';
import C_2040_EJCS_conso_2 from '../../../assets/images/symbols/C_2040_EJCS_conso_2.svg';
import C_2040_EJCS_conso_3 from '../../../assets/images/symbols/C_2040_EJCS_conso_3.svg';

import E_2030_EJCS_conso_1 from '../../../assets/images/symbols/E_2030_EJCS_conso_1.svg';
import E_2030_EJCS_conso_2 from '../../../assets/images/symbols/E_2030_EJCS_conso_2.svg';
import E_2035_EJCS_conso_1 from '../../../assets/images/symbols/E_2035_EJCS_conso_1.svg';
import E_2035_EJCS_conso_2 from '../../../assets/images/symbols/E_2035_EJCS_conso_2.svg';
import E_2040_EJCS_conso_1 from '../../../assets/images/symbols/E_2040_EJCS_conso_1.svg';
import E_2040_EJCS_conso_2 from '../../../assets/images/symbols/E_2040_EJCS_conso_2.svg';

import E_2030_HNVC_conso_1 from '../../../assets/images/symbols/E_2030_HNVC_conso_1.svg';
import E_2030_HNVC_conso_2 from '../../../assets/images/symbols/E_2030_HNVC_conso_2.svg';
import E_2035_HNVC_conso_1 from '../../../assets/images/symbols/E_2035_HNVC_conso_1.svg';
import E_2035_HNVC_conso_2 from '../../../assets/images/symbols/E_2035_HNVC_conso_2.svg';
import E_2040_HNVC_conso_1 from '../../../assets/images/symbols/E_2040_HNVC_conso_1.svg';
import E_2040_HNVC_conso_2 from '../../../assets/images/symbols/E_2040_HNVC_conso_2.svg';

import I_2030_EJCS_conso_1 from '../../../assets/images/symbols/I_2030_EJCS_conso_1.svg';
import I_2030_EJCS_conso_2 from '../../../assets/images/symbols/I_2030_EJCS_conso_2.svg';
import I_2035_EJCS_conso_1 from '../../../assets/images/symbols/I_2035_EJCS_conso_1.svg';
import I_2035_EJCS_conso_2 from '../../../assets/images/symbols/I_2035_EJCS_conso_2.svg';
import I_2040_EJCS_conso_1 from '../../../assets/images/symbols/I_2040_EJCS_conso_1.svg';
import I_2040_EJCS_conso_2 from '../../../assets/images/symbols/I_2040_EJCS_conso_2.svg';

import I_2030_HNVC_conso_1 from '../../../assets/images/symbols/I_2030_HNVC_conso_1.svg';
import I_2030_HNVC_conso_2 from '../../../assets/images/symbols/I_2030_HNVC_conso_2.svg';
import I_2035_HNVC_conso_1 from '../../../assets/images/symbols/I_2035_HNVC_conso_1.svg';
import I_2035_HNVC_conso_2 from '../../../assets/images/symbols/I_2035_HNVC_conso_2.svg';
import I_2040_HNVC_conso_1 from '../../../assets/images/symbols/I_2040_HNVC_conso_1.svg';
import I_2040_HNVC_conso_2 from '../../../assets/images/symbols/I_2040_HNVC_conso_2.svg';

const useConsoGouletLayer = () => {
  const currentYear = useAppSelector(selectCurrentYear);
  const currentGouletZone = useAppSelector(selectCurrentGouletZone);
  const currentClimat = useAppSelector(selectStateClimate);
  const gouletSymbol = useAppSelector(selectGouletSymbols);

  const getSymbolData = () => {
    return gouletSymbol.filter((item: GouletSymbolFeature) => {
      const { categorie } = item.properties;
      const mix = splitYear(currentYear).toString();
      const prefix = `${currentGouletZone}_${mix}_${currentClimat}_conso`;
      return categorie.startsWith(prefix);
    });
  };

  const getSymbolIcon = (categorie: string): any => {
    switch (categorie) {
      case 'B_2030_EJCS_conso_1':
        return {
          url: B_2030_EJCS_conso_1,
          width: 136,
          height: 118,
        };
      case 'B_2030_EJCS_conso_2':
        return {
          url: B_2030_EJCS_conso_2,
          width: 222,
          height: 142,
        };
      case 'B_2030_HNVC_conso_1':
        return {
          url: B_2030_HNVC_conso_1,
          width: 136,
          height: 119,
        };
      case 'B_2030_HNVC_conso_2':
        return {
          url: B_2030_HNVC_conso_2,
          width: 222,
          height: 142,
        };

      case 'B_2035_EJCS_conso_1':
        return {
          url: B_2035_EJCS_conso_1,
          width: 136,
          height: 119,
        };
      case 'B_2035_EJCS_conso_2':
        return {
          url: B_2035_EJCS_conso_2,
          width: 222,
          height: 142,
        };
      case 'B_2035_HNVC_conso_1':
        return {
          url: B_2035_HNVC_conso_1,
          width: 136,
          height: 118,
        };
      case 'B_2035_HNVC_conso_2':
        return {
          url: B_2035_HNVC_conso_2,
          width: 222,
          height: 143,
        };

      case 'B_2040_EJCS_conso_1':
        return {
          url: B_2040_EJCS_conso_1,
          width: 134,
          height: 116,
        };
      case 'B_2040_EJCS_conso_2':
        return {
          url: B_2040_EJCS_conso_2,
          width: 220,
          height: 140,
        };
      case 'B_2040_HNVC_conso_1':
        return {
          url: B_2040_HNVC_conso_1,
          width: 136,
          height: 118,
        };
      case 'B_2040_HNVC_conso_2':
        return {
          url: B_2040_HNVC_conso_2,
          width: 222,
          height: 142,
        };

      case 'C_2030_EJCS_conso_1':
        return {
          url: C_2030_EJCS_conso_1,
          width: 136,
          height: 119,
        };
      case 'C_2030_EJCS_conso_2':
        return {
          url: C_2030_EJCS_conso_2,
          width: 222,
          height: 142,
        };
      case 'C_2030_EJCS_conso_3':
        return {
          url: C_2030_EJCS_conso_3,
          width: 165,
          height: 151,
        };
      case 'C_2035_EJCS_conso_1':
        return {
          url: C_2035_EJCS_conso_1,
          width: 136,
          height: 118,
        };
      case 'C_2035_EJCS_conso_2':
        return {
          url: C_2035_EJCS_conso_2,
          width: 222,
          height: 142,
        };
      case 'C_2035_EJCS_conso_3':
        return {
          url: C_2035_EJCS_conso_3,
          width: 165,
          height: 150,
        };
      case 'C_2040_EJCS_conso_1':
        return {
          url: C_2040_EJCS_conso_1,
          width: 136,
          height: 118,
        };
      case 'C_2040_EJCS_conso_2':
        return {
          url: C_2040_EJCS_conso_2,
          width: 222,
          height: 142,
        };
      case 'C_2040_EJCS_conso_3':
        return {
          url: C_2040_EJCS_conso_3,
          width: 165,
          height: 150,
        };

      case 'E_2030_EJCS_conso_1':
        return {
          url: E_2030_EJCS_conso_1,
          width: 166,
          height: 150,
        };
      case 'E_2030_EJCS_conso_2':
        return {
          url: E_2030_EJCS_conso_2,
          width: 210,
          height: 134,
        };
      case 'E_2035_EJCS_conso_1':
        return {
          url: E_2035_EJCS_conso_1,
          width: 165,
          height: 151,
        };
      case 'E_2035_EJCS_conso_2':
        return {
          url: E_2035_EJCS_conso_2,
          width: 222,
          height: 142,
        };
      case 'E_2040_EJCS_conso_1':
        return {
          url: E_2040_EJCS_conso_1,
          width: 165,
          height: 150,
        };
      case 'E_2040_EJCS_conso_2':
        return {
          url: E_2040_EJCS_conso_2,
          width: 210,
          height: 134,
        };
      case 'E_2030_HNVC_conso_1':
        return {
          url: E_2030_HNVC_conso_1,
          width: 165,
          height: 151,
        };
      case 'E_2030_HNVC_conso_2':
        return {
          url: E_2030_HNVC_conso_2,
          width: 210,
          height: 134,
        };
      case 'E_2035_HNVC_conso_1':
        return {
          url: E_2035_HNVC_conso_1,
          width: 165,
          height: 151,
        };
      case 'E_2035_HNVC_conso_2':
        return {
          url: E_2035_HNVC_conso_2,
          width: 210,
          height: 134,
        };
      case 'E_2040_HNVC_conso_1':
        return {
          url: E_2040_HNVC_conso_1,
          width: 165,
          height: 151,
        };
      case 'E_2040_HNVC_conso_2':
        return {
          url: E_2040_HNVC_conso_2,
          width: 210,
          height: 134,
        };

      case 'I_2030_EJCS_conso_1':
        return {
          url: I_2030_EJCS_conso_1,
          width: 78,
          height: 54,
        };
      case 'I_2030_EJCS_conso_2':
        return {
          url: I_2030_EJCS_conso_2,
          width: 70,
          height: 93,
        };
      case 'I_2030_HNVC_conso_1':
        return {
          url: I_2030_HNVC_conso_1,
          width: 78,
          height: 55,
        };
      case 'I_2030_HNVC_conso_2':
        return {
          url: I_2030_HNVC_conso_2,
          width: 70,
          height: 93,
        };

      case 'I_2035_EJCS_conso_1':
        return {
          url: I_2035_EJCS_conso_1,
          width: 79,
          height: 55,
        };
      case 'I_2035_EJCS_conso_2':
        return {
          url: I_2035_EJCS_conso_2,
          width: 70,
          height: 93,
        };
      case 'I_2035_HNVC_conso_1':
        return {
          url: I_2035_HNVC_conso_1,
          width: 78,
          height: 55,
        };
      case 'I_2035_HNVC_conso_2':
        return {
          url: I_2035_HNVC_conso_2,
          width: 70,
          height: 93,
        };

      case 'I_2040_EJCS_conso_1':
        return {
          url: I_2040_EJCS_conso_1,
          width: 78,
          height: 54,
        };
      case 'I_2040_EJCS_conso_2':
        return {
          url: I_2040_EJCS_conso_2,
          width: 70,
          height: 93,
        };
      case 'I_2040_HNVC_conso_1':
        return {
          url: I_2040_HNVC_conso_1,
          width: 78,
          height: 54,
        };
      case 'I_2040_HNVC_conso_2':
        return {
          url: I_2040_HNVC_conso_2,
          width: 70,
          height: 93,
        };
      default:
        return;
    }
  };

  const getOffsetIcon = (categorie: string): any => {
    switch (categorie) {
      case 'B_2030_EJCS_conso_1':
        return [-4, 5];
      case 'B_2030_EJCS_conso_2':
        return [-15, 0];
      case 'B_2035_EJCS_conso_1':
        return [-4, 5];
      case 'B_2035_EJCS_conso_2':
        return [-15, 0];
      case 'B_2040_EJCS_conso_1':
        return [-4, 5];
      case 'B_2040_EJCS_conso_2':
        return [-15, 0];
      case 'B_2030_HNVC_conso_1':
        return [-10, 10];
      case 'B_2030_HNVC_conso_2':
        return [-15, 0];
      case 'B_2035_HNVC_conso_1':
        return [-10, 10];
      case 'B_2035_HNVC_conso_2':
        return [-15, 0];
      case 'B_2040_HNVC_conso_1':
        return [-10, 10];
      case 'B_2040_HNVC_conso_2':
        return [-15, 0];
      case 'C_2030_EJCS_conso_2':
        return [-15, 0];
      case 'C_2035_EJCS_conso_2':
        return [-15, 0];
      case 'C_2040_EJCS_conso_2':
        return [-15, 0];
      case 'E_2030_EJCS_conso_2':
        return [-50, -30];
      case 'E_2035_EJCS_conso_2':
        return [-55, -35];
      case 'E_2040_EJCS_conso_2':
        return [-55, -35];
      case 'E_2030_HNVC_conso_2':
        return [-10, -10];
      case 'E_2035_HNVC_conso_2':
        return [-10, -10];
      case 'E_2040_HNVC_conso_2':
        return [-10, -10];
      default:
        return;
    }
  };

  const layer = new IconLayer({
    id: 'conso-layer',
    data: getSymbolData(),
    getIcon: (d: any) => {
      return getSymbolIcon(d.properties.categorie);
    },
    getPosition: (d: any) => {
      return d.position;
    },
    getSize: (d: any) => getSymbolIcon(d.properties.categorie).height,
    getPixelOffset: (d: any) => getOffsetIcon(d.properties.categorie),
    getColor: () => [255, 255, 255, 100],
    pickable: false,
  });

  return [layer];
};

export default useConsoGouletLayer;
