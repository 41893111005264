import styled from 'styled-components';
import { useAppSelector } from '../../store/hooks';
import {
  selectCurrentYear,
  selectStateClimate,
} from '../../store/mainReducer';
import RightText from '../../data/goulet/GOULETS_RIGHT_TXT.json';
import { splitYear } from '../../utils';

const StyledDiv = styled.div`
  &.right-area {
    position: relative;

  .close {
    position: absolute;
    top:10px;
    right: 10px;
    cursor: pointer;
  }
  .text {
  font-size: 14px;
  }
  .infos {
    background: rgba(10, 90, 114, 1);
     border-radius: 6px;
}
`;

const RightGouletComponent = () => {
  const currentYear = useAppSelector(selectCurrentYear);
  const selectedClimate = useAppSelector(selectStateClimate);

  const currentText = RightText.filter(
    (item) =>
      item.year === splitYear(currentYear).toString() &&
      item.day === selectedClimate,
  )[0];

  return (
    <StyledDiv className="right-area">
      <div style={{rowGap: "15px"}} className="row p-2">
        {/* <div className="col-12 text-center">
          <h5 className='mb-0'>{currentText.title && currentText.title}</h5>
        </div>
        <div className="col-12">
          {currentText.text && (
            <div
              className="p-2 text"
              dangerouslySetInnerHTML={{
                __html: currentText.text,
              }}
            ></div>
          )}
        </div>
        <div className="col-12 mt-1">
          <div className="infos p-2">
            <h5>{'L’INFO CLEF'}</h5>
            {currentText.infos && (
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: currentText.infos,
                }}
              ></div>
            )}
          </div>
        </div> */}
      </div>
    </StyledDiv>
  );
};

export default RightGouletComponent;
