import GouletMapComponent from './MapGouletComponent';
import LeftGouletComponent from './LeftGouletComponent';
import RightGouletComponent from './RightGouletComponent';
import styled from 'styled-components';

const StyledDiv = styled.div`
  .sidebar {
    height: 100vh;
  }
  .overflow {
    overflow-y: auto;
  }
`;

function GouletComponent() {
  return (
    <>
      <StyledDiv className="container-fluid">
        <div className="row vh-100-md p-10">
          <div className="col-12 order-1 order-md-0 col-md-2 d-none d-md-block sidebar">
            <LeftGouletComponent />
          </div>
          <div
            className="col-12 order-0 order-md-1 col-md-8 vh-md-100 vh-sm-100 pe-2 ps-2 shadow-sm"
            style={{ position: 'relative' }}
          >
            <GouletMapComponent />
          </div>
          <div className="col-12 order-2 order-md-2 col-md-2 d-none d-md-block sidebar overflow">
            <RightGouletComponent />
          </div>
        </div>
      </StyledDiv>
    </>
  );
}

export default GouletComponent;
