import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  selectAdaptationPointIn,
  selectCurrentPointActive,
  setAdaptationPointIn,
  setCadreActivePointIn,
  setCategoryActivePointIn,
  setConsoPolygons,
  setCurrentYear,
  setFuseauPolygons,
  setIconePointIn,
  setInitStateViewPointIn,
  setLignesPointIn,
  setPostPointIn,
} from '../../store/mainReducer';
import { CURRENT_YEAR_VALUES } from '../../utils/enums';
import {
  getCadrePointsIn,
  getConsoPolygons,
  getFuseauPolygons,
  getIconesPointsIn,
  getInitStatView,
  getLignesPoints,
  getPostsPointsIn,
} from '../../services';

const StyledDiv = styled.div`
  width: 235px;
  height: 20px;
  position: relative;
  cursor: pointer;
  z-index: 2;
  padding-top: 0.15rem;
  .switch-button {
    width: 45px;
    height: 12px;
    position: absolute;
    left: 0;
    top: 5px;
    background-color: #999fa1;
    border-radius: 60px;
    transition: background-color 0.3s;
    &.on {
      background-color: #82d4f6;
    }
  }
  .switch-circle {
    width: 22.5px;
    height: 23px;
    position: absolute;
    left: 0;
    top: 0;
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    border-radius: 23px;
    transition: transform 0.3s, background-color 0.3s;
    z-index: 99;
    &.on {
      transform: translateX(25px);
      background-color: #009cdf;
    }
  }
  .text {
    position: absolute;
    left: 30px;
    top: 2px;
  }
`;
const ZoomSwitcher = () => {
  const dispatch = useAppDispatch();
  const adaptationPointIn = useAppSelector(selectAdaptationPointIn);
  const currentPointActive = useAppSelector(selectCurrentPointActive);

  const setUpdateDataByZoom = (year: string) => {
    dispatch(setCategoryActivePointIn('pas de click'));
    
    const data_ligne = getLignesPoints(currentPointActive, year);
    dispatch(setLignesPointIn(data_ligne));

    const data_icones = getIconesPointsIn(currentPointActive, year);
    dispatch(setIconePointIn(data_icones));

    const data_post = getPostsPointsIn(currentPointActive, year);
    dispatch(setPostPointIn(data_post));

    const data_cadre = getCadrePointsIn(currentPointActive);
    dispatch(setCadreActivePointIn(data_cadre));

    const conso_polygons = getConsoPolygons();
    dispatch(setConsoPolygons(conso_polygons));
    const fuseau_polygons = getFuseauPolygons();
    dispatch(setFuseauPolygons(fuseau_polygons));
  };

  const setUpdateStateView = (year: string) => {
    const currentYearValue =
      CURRENT_YEAR_VALUES[year as keyof typeof CURRENT_YEAR_VALUES];

    const state = getInitStatView(currentPointActive, currentYearValue);
    dispatch(setInitStateViewPointIn(state));
  };

  const handleToggle = () => {
    const active = !adaptationPointIn;
    const currentYear = active ? 'YEAR_ADAPT_2030' : 'YEAR_2030';
    const currentYearValue =
      CURRENT_YEAR_VALUES[currentYear as keyof typeof CURRENT_YEAR_VALUES];

    dispatch(setAdaptationPointIn(active));
    dispatch(setCurrentYear(currentYear));

    setUpdateStateView(currentYear);
    setUpdateDataByZoom(currentYearValue);
  };

  return (
    <StyledDiv onClick={handleToggle} className="mb-3 d-flex">
      <div className={`switch-circle ${adaptationPointIn ? 'on' : 'off'}`} />
      <div className={`switch-button ${adaptationPointIn ? 'on' : 'off'}`} />
      <div className="mx-4 text">{'Adaptations envisagées'}</div>
    </StyledDiv>
  );
};

export default ZoomSwitcher;
