import React from 'react';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';

import Ligne400kv from '../../assets/images/goulet/ligne400.svg';
import ligne400Sat from '../../assets/images/goulet/ligne400Sat.svg';
import Conso from '../../assets/images/goulet/conso.svg';
import Exchange from '../../assets/images/goulet/exchange.svg';
import FluxPrincipal from '../../assets/images/goulet/fluxPrincipal.svg';
import ProdNucleaire from '../../assets/images/goulet/prodNucleaire.svg';
import ProdSoliare from '../../assets/images/goulet/prodSoliare.svg';

const StyledDiv = styled.div`
  color: rgba(255, 255, 255, 1);
  background-color: rgba(10, 90, 114, 1);
  border: none;
  margin-bottom: 1rem;
  border-radius: 0.6rem;
  padding-left: 0.5rem;
  min-height: 25rem;
  .icon {
    width: 1.4rem;
  }
  .info {
    text-wrap: nowrap;
    font-size: 0.9rem;
  }
`;

const ItemLegendGoulet = () => {

  return (
    <StyledDiv>
      <div className="col-12  mb-1 mt-3">
        <h5>{'Légende :'}</h5>
      </div>
      <Row className="align-items-center mb-2">
        <div className="col-2">
          <img className="icon" src={Ligne400kv} alt={''} />
        </div>
        <div className="col-10">
          <div className="align-items-center info">{'Ligne 400 kV'}</div>
        </div>
      </Row>
      <Row className="align-items-center mb-2">
        <div className="col-2">
          <img className="icon" src={ligne400Sat} alt={''} />
        </div>
        <div className="col-10">
          <div className="align-items-center info">{'Ligne 400 kV sous contrainte'}</div>
        </div>
      </Row>
      <Row className="align-items-center mb-2">
        <div className="col-2">
          <img className="icon" src={FluxPrincipal} alt={''} />
        </div>
        <div className="col-10">
          <div className="align-items-center info">{'Flux principaux'}</div>
        </div>
      </Row>
      <Row className="align-items-center mb-2">
        <div className="col-12">
          <div className="align-items-center info">{'Inducteurs de contraintes'}</div>
        </div>
      </Row>
      <Row className="align-items-center mb-2">
        <div className="col-2">
          <img className="icon" src={ProdNucleaire} alt={''} />
        </div>
        <div className="col-10">
          <div className="align-items-center info">
            {'Production nucléaire'}
          </div>
        </div>
      </Row>
      <Row className="align-items-center mb-2">
        <div className="col-2">
          <img className="icon" src={ProdSoliare} alt={''} />
        </div>
        <div className="col-10">
          <div className="align-items-center info">
            {'Production solaire (diffus)'}
          </div>
        </div>
      </Row>
      <Row className="align-items-center mb-2">
        <div className="col-2">
          <img className="icon" src={Conso} alt={''} />
        </div>
        <div className="col-10">
          <div className="align-items-center info">
            {'Consommation'}
          </div>
        </div>
      </Row>
      <Row className="align-items-center mb-2">
        <div className="col-2">
          <img className="icon" src={Exchange} alt={''} />
        </div>
        <div className="col-10">
          <div className="align-items-center info">
            {'Échange international'}
          </div>
        </div>
      </Row>
    </StyledDiv>
  );
};

export default ItemLegendGoulet;
