import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  selectFlux,
  toggleFlux,
} from '../../../../store/mainReducer';

import ItemLegend from './ItemLegend';

interface ItemsProps {
  nameToggle: string;
  title: string;
  src: string;
  displayEye: boolean;
  tooltip: boolean;
}

const LegendSegment: React.FC<ItemsProps> = ({
  nameToggle,
  title,
  src,
  displayEye = false,
  tooltip = false,
}) => {
  const dispatch = useAppDispatch();
  const activeFlux = useAppSelector(selectFlux);

  const handleSwitchToggle = () => {
    dispatch(toggleFlux());
  };

  return (
    <div className="row  align-items-center">
      <div className="col-12">
        {nameToggle !== undefined && (
          <ItemLegend
            handleClick={handleSwitchToggle}
            active={activeFlux}
            title={title}
            src={src}
            displayEye={displayEye}
            tooltip={tooltip}
          />
        )}
      </div>
    </div>
  );
};

export default LegendSegment;
