import * as React from 'react';

import styled from 'styled-components';
import CloseModal from '../../assets/images/closeModal.svg';
import {
  selectCurrentPointActive,
  selectElementHeightPointIn,
  selectInitStateViewPointIn,
  setAdaptationPointIn,
  setCadreActivePointIn,
  setCategoryActivePointIn,
  setConsoPolygons,
  setCurrentYear,
  setCurrentZoom,
  setIconePointIn,
  setInitStateViewPointIn,
  setLignesPointIn,
  setPostPointIn,
} from '../../store/mainReducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  getCadrePointsIn,
  getConsoPolygons,
  getIconesPointsIn,
  getInitStatView,
  getLignesPoints,
  getPostsPointsIn,
  getFuseauPolygons,
} from '../../services';
import { FlyToInterpolator } from 'deck.gl';
import { CURRENT_YEAR_VALUES } from '../../utils/enums';

interface ContentProps {
  handleClose: () => void;
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
`;

const ContentPointInComponent: React.FC<ContentProps> = ({ handleClose }) => {
  const dispatch = useAppDispatch();

  const heightArea = useAppSelector(selectElementHeightPointIn);
  const currentPointActive = useAppSelector(selectCurrentPointActive);
  const initStateViewPointIn = useAppSelector(selectInitStateViewPointIn);
  const [viewState, setViewState] = React.useState(initStateViewPointIn);

  const contentWidth = heightArea ? heightArea - 40 : 696;
  const radialWidth = heightArea
    ? Number(heightArea - 40) / 2
    : Number(696 / 2);

  const setUpdateStateView = (year: string, newZoom: number) => {
    let longitude = initStateViewPointIn.longitude;
    let latitude = initStateViewPointIn.latitude;

    const state = getInitStatView(currentPointActive, year);
    longitude = state.longitude;
    latitude = state.latitude;

    setViewState((prevState: any) => ({
      ...prevState,
      zoom: newZoom,
      latitude,
      longitude,
      transitionDuration: 600,
      // transitionInterpolator: new FlyToInterpolator(),
    }));

    dispatch(setInitStateViewPointIn(state));
  };

  const setUpdateDataByZoom = (year: string) => {
    dispatch(setCategoryActivePointIn('pas de click'));
    dispatch(setAdaptationPointIn(false));

    const data_ligne = getLignesPoints(currentPointActive, year);
    dispatch(setLignesPointIn(data_ligne));

    const data_icones = getIconesPointsIn(currentPointActive, year);
    dispatch(setIconePointIn(data_icones));

    const data_post = getPostsPointsIn(currentPointActive, year);
    dispatch(setPostPointIn(data_post));

    const data_cadre = getCadrePointsIn(currentPointActive);
    dispatch(setCadreActivePointIn(data_cadre));

    const conso_polygons = getConsoPolygons();
    dispatch(setConsoPolygons(conso_polygons));

    const fuseau_polygons = getFuseauPolygons();
    dispatch(setConsoPolygons(fuseau_polygons));
  };

  const handleCloseModal = (): void => {
    const year = `YEAR_2024`;

    const currentYearValue =
    CURRENT_YEAR_VALUES[year as keyof typeof CURRENT_YEAR_VALUES];
    
    setUpdateStateView(currentYearValue, 8);
    setUpdateDataByZoom(currentYearValue);
    handleClose();
  };

  const styleOverlay = {
    background: `radial-gradient(
      circle,
      transparent ${radialWidth}px,
      rgba(0, 0, 0, 0.7) 58%
    )`,
  };

  const styleContainer = {
    width: `${contentWidth}px`,
    height: `${contentWidth}px`,
  };

  return (
    <StyledContainer>
      <div className="point-overlay" style={styleOverlay}></div>
      <div
        className="point-container"
        style={styleContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="point-close"
          onClick={handleCloseModal}
          style={{ position: 'absolute', top: '1.4rem' }}
        >
          <img src={CloseModal} alt="" />
        </button>
        <div className="point-content"></div>
      </div>
    </StyledContainer>
  );
};

export default ContentPointInComponent;
