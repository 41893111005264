import { useAppDispatch, useAppSelector } from '../../store/hooks';
import styled from 'styled-components';
import {
  selectCurrentYear,
  selectCategoryActivePointIn,
  setCategoryActivePointIn,
} from '../../store/mainReducer';

import Ligne225kV from '../../assets/images/points/right/ligne225kV.svg';
import Ligne400KV from '../../assets/images/points/right/ligne400KV.svg';
import Ligne400kvActive from '../../assets/images/points/right/ligne400kvActive.svg';
import Ligne225kvActive from '../../assets/images/points/right/ligne225kvActive.svg';
import DecarbonationHydrogene from '../../assets/images/points/right/DecarbonationHydrogene.svg';
import Industrialisation from '../../assets/images/points/right/Industrialisation.svg';
import ElectrificationDesUsages from '../../assets/images/points/right/ElectrificationDesUsages.svg';
import DecarbonationHydrogeneActive from '../../assets/images/points/right/DecarbonationHydrogeneActive.svg';
import IndustrialisationActive from '../../assets/images/points/right/IndustrialisationActive.svg';
import ElectrificationDesUsagesActive from '../../assets/images/points/right/ElectrificationDesUsagesActive.svg';

import Poste_P2 from '../../assets/images/points/right/Poste_P2.svg';
import Poste_P1_active from '../../assets/images/points/right/Poste_P1_active.svg';
import New400kV_P1 from '../../assets/images/points/right/New400kV_P1.svg';
import New400kV_P1_active from '../../assets/images/points/right/New400kV_P1_active.svg';
import New400kV_P2 from '../../assets/images/points/right/New400kV_P2.svg';
import New400kV_P2_active from '../../assets/images/points/right/New400kV_P2_active.svg';

import DATA_RIGHT_SIDEBAR from '../../data/points/data_right_sidebar.json';
import { useState } from 'react';
import { CURRENT_YEAR_VALUES } from '../../utils/enums';

const StyledDiv = styled.div`
  h5 {
    text-transform: capitalize;
  }
  .number {
    border: 1px solid #fff;
    text-align: center;
    font-weight: 600;
    padding: 5px 8px;
    margin-right: 1rem;
    display: inline;
    border-radius: 0.2rem;
  }
  .font-bold {
    font-weight: bold;
  }
  .font-normal {
    font-weight: normal;
  }
`;

const RightPointComponent = () => {
  const dispatch = useAppDispatch();
  const currentYear = useAppSelector(selectCurrentYear);
  const activePointIn = useAppSelector(selectCategoryActivePointIn);
  const [selectedCategory, setSelectedCategory] = useState<string>('pas de click');

  const currentYearValue =
    CURRENT_YEAR_VALUES[currentYear as keyof typeof CURRENT_YEAR_VALUES];

  const currentData = DATA_RIGHT_SIDEBAR.filter(
    (elt: any) => elt.year === currentYearValue,
  )[0];
  const getIconesByCategory = (category: string, active: boolean) => {
    switch (category) {
      case '225':
        if (active) {
          return Ligne225kvActive;
        } else {
          return Ligne225kV;
        }

      case '400':
        if (active) {
          return Ligne400kvActive;
        } else {
          return Ligne400KV;
        }

        case 'co2h2':
        if (active) {
          return DecarbonationHydrogeneActive;
        } else {
          return DecarbonationHydrogene;
        }

        case 'industrie':
        if (active) {
          return IndustrialisationActive;
        } else {
          return Industrialisation;
        }

        case 'electrification':
        if (active) {
          return ElectrificationDesUsagesActive;
        } else {
          return ElectrificationDesUsages;
        }

        case '400 - palier 1':
        if (active) {
          return New400kV_P1_active;
        } else {
          return New400kV_P1;
        }

        case '400 - palier 2':
        if (active) {
          return New400kV_P2_active;
        } else {
          return New400kV_P2;
        }

        case 'transfo':
        if (active) {
          return Poste_P1_active;
        } else {
          return Poste_P2;
        }
    }
  };

  const handleActivePointIn = (category: string): void => {
    if (selectedCategory === category) {
      setSelectedCategory('pas de click');
      dispatch(setCategoryActivePointIn('pas de click'));
    } else {
      setSelectedCategory(category);
      dispatch(setCategoryActivePointIn(category));
    }
  };
  console.log(activePointIn);
  return (
    <div className="right-area">
      {currentData && (
        <div className="row p-3">
          <div className="col-12 pt-2">
            <StyledDiv className="row pt-3"> 
            {currentData.year === CURRENT_YEAR_VALUES['YEAR_ADAPT_2030'] && 
              <div className='col-12'>
                <div className="row text-center">
                  <div className={`${activePointIn === '400 - palier 1' || activePointIn === 'transfo' ? 'font-bold' : 'font-normal'} col-6`}>Palier 1</div>
                  <div className={`${activePointIn === '400 - palier 2' ? 'font-bold' : 'font-normal'} col-6`}>Palier 2</div>
                </div>
              </div>}
              <div className="col-12">
                <div className="row">
                  {currentData.steps.map((item: any, index: number) => {
                    return (
                      <div className={`${item.category === '400 - palier 2' ? 'col-6 text-center' : 'col-3'}`} key={index}>
                        
                        <img
                          src={getIconesByCategory(
                            item.category,
                            activePointIn === item.category,
                          )}
                          alt={`${item.category}`}
                          onClick={() => handleActivePointIn(item.category)}
                          style={{cursor: 'pointer'}}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </StyledDiv>
          </div>
        </div>
      )}
    </div>
  );
};

export default RightPointComponent;
