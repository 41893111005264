import { useEffect } from 'react';

import LeftAreaComponent from './left-area';
import RightAreaComponent from './right-area';

import ResponsiveBtnsComponent from './map-area/ResponsiveBtnsComponent';
import MapAreaComponent from './map-area';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  setSegments,
  setSites,
  setGoulets,
  setZoomPoints,
  setSidebarProductions,
  selectCurrentYear,
  setGouletIcons,
  setGrid,
  setGouletSymbols,
} from '../../store/mainReducer';


import {
  getSidebarProd,
  getSitesByYear,
  getGoulet,
  getZoomPoints,
  getSegmentsByYear,
  getGouletIcons,
  getGouletGrid,
  getGouletSymbols,
} from '../../services';

function InitMap() {
  const currentYear = useAppSelector(selectCurrentYear);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const data_segment = getSegmentsByYear(currentYear);
    dispatch(setSegments(data_segment));

    const sites = getSitesByYear(currentYear);
    dispatch(setSites(sites));

    const goulets = getGoulet();
    dispatch(setGoulets(goulets));

    const goulets_symbols = getGouletSymbols();
    dispatch(setGouletSymbols(goulets_symbols));

    const zoomPoints = getZoomPoints();
    dispatch(setZoomPoints(zoomPoints));

    const gouletIcons = getGouletIcons();
    dispatch(setGouletIcons(gouletIcons));

    const productions = getSidebarProd(currentYear);
    dispatch(setSidebarProductions(productions));
    
    const grid = getGouletGrid();
    dispatch(setGrid(grid));
  }, [dispatch]);

  return (
    <>
      <div className="container-fluid">
        <div className="row vh-100-md p-10">
          <div className="col-12 order-1 order-md-0 col-md-2 d-none d-md-block sidebar">
            <LeftAreaComponent />
          </div>
          <div
            className="col-12 order-0 order-md-1 col-md-8 vh-md-100 vh-sm-100 pe-2 ps-2 shadow-sm"
            style={{ position: 'relative' }}
          >
            <MapAreaComponent />
          </div>
          <div className="col-12 order-2 order-md-2 col-md-2 d-none d-md-block sidebar">
            <RightAreaComponent />
          </div>
          <div className="col-12 d-block d-md-none">
            <ResponsiveBtnsComponent />
          </div>
        </div>
      </div>
    </>
  );
}

export default InitMap;
