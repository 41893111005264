import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { SegmentFeature } from '../models/SegmentFeature';
import { ProdProperties, SiteFeature } from '../models/SiteFeature';
import {
  GouletFeature,
  GouletIconFeature,
  GouletPathFeature,
} from '../models/GouletFeature';
import { CURRENT_DAY, CURRENT_YEAR, CURRENT_YEAR_VALUES } from '../utils/enums';
import {
  ConsoPolygonFeature,
  FuseauPolygonFeature,
  IconePointFeature,
  LignePointFeature,
  NumberPointProperties,
  PostTPointFeature,
  ZoomInitStateFeature,
  ZoomPointFeature,
} from '../models/ZoomFeature';
import { GouletSymbolFeature } from '../models/GouletSymbolFeature';

interface ToggleMixElec {
  ligneCCHT: boolean;
  wind: boolean;
  photovoltaic: boolean;
  nuclear: boolean;
  thermic: boolean;
  hydraulic: boolean;
  line400kV: boolean;
  other: boolean;
}

interface ToggleModalActive {
  legend: boolean; // Réseau
  simulator: boolean;
}

const initToggleModalActive: ToggleModalActive = {
  legend: false,
  simulator: false,
};

const initialToggleMixElec: ToggleMixElec = {
  ligneCCHT: true,
  line400kV: true,
  wind: true,
  photovoltaic: true,
  nuclear: true,
  thermic: true,
  other: true,
  hydraulic: true,
};

interface MainState {
  currentYear: string;
  segments: SegmentFeature[];
  sites: SiteFeature[];
  goulets: {
    polygons: GouletFeature[];
    icons: GouletIconFeature[];
    paths: GouletPathFeature[];
    currentGouletZone: string;
    grid: SiteFeature[];
    symbols: GouletSymbolFeature[];
  };
  zoom: {
    zoomPoints: ZoomPointFeature[];
    currentZoom: ZoomPointFeature | null;
    currentZoomActive: boolean;
  };
  pointsIn: {
    currentPointActive: string;
    lignes: LignePointFeature[];
    iconePoints: IconePointFeature[];
    number: NumberPointProperties[];
    postPoints: PostTPointFeature[];
    cadreActivePoints: any[];
    elementHeight: number;
    numberActive: number;
    initStateView: ZoomInitStateFeature;
    categoryActive: string;
    adaptation: boolean;
    consoPolygons: ConsoPolygonFeature[];
    fuseauPolygons: FuseauPolygonFeature[];
  };
  productions: ProdProperties[];
  toggleMixElec: ToggleMixElec;
  stateClimate: string;
  toggleModalActive: ToggleModalActive;
  toggleProd: boolean;
  toggleFlux: boolean;
  consommation: boolean;
  tooltip: any;
}

const initialState: MainState = {
  currentYear: CURRENT_YEAR.YEAR_2024,
  segments: [],
  sites: [],
  goulets: {
    polygons: [],
    icons: [],
    paths: [],
    currentGouletZone: '',
    grid: [],
    symbols: [],
  },
  zoom: {
    zoomPoints: [],
    currentZoom: null,
    currentZoomActive: false,
  },
  pointsIn: {
    currentPointActive: '',
    lignes: [],
    iconePoints: [],
    number: [],
    postPoints: [],
    cadreActivePoints: [],
    elementHeight: 0,
    numberActive: 0,
    initStateView: {
      longitude: 5.366731073101268,
      latitude: 43.62213550368543,
      zoom: 8,
    },
    categoryActive: 'pas de click',
    adaptation: false,
    consoPolygons: [],
    fuseauPolygons: []
  },
  productions: [],
  toggleProd: true,
  consommation: true,
  toggleFlux: true,
  toggleMixElec: initialToggleMixElec,
  stateClimate: CURRENT_DAY.EJCS,
  toggleModalActive: initToggleModalActive,
  tooltip: null,
};

export const mainSlice = createSlice({
  name: 'Main',
  initialState,
  reducers: {
    setCurrentYear: (state, action) => {
      state.currentYear = action.payload;
    },
    setTooltip: (state, action) => {
      state.tooltip = action.payload;
    },
    setStateClimate: (state, action) => {
      state.stateClimate = action.payload;
    },
    toggleFlux: (state) => {
      state.toggleFlux = !state.toggleFlux;
    },
    toggleModalLegend: (state, action) => {
      state.toggleModalActive.legend = action.payload;
    },
    toggleModalSimulator: (state, action) => {
      state.toggleModalActive.simulator = action.payload;
    },
    setSegments: (state, action) => {
      state.segments = action.payload;
    },
    setSites: (state, action) => {
      state.sites = action.payload;
    },
    setGrid: (state, action) => {
      state.goulets.grid = action.payload;
    },
    setGoulets: (state, action) => {
      state.goulets.polygons = action.payload;
    },
    setGouletSymbols: (state, action) => {
      state.goulets.symbols = action.payload;
    },
    setGouletIcons: (state, action) => {
      state.goulets.icons = action.payload;
    },
    setPathIcons: (state, action) => {
      state.goulets.paths = action.payload;
    },
    setZoomPoints: (state, action) => {
      state.zoom.zoomPoints = action.payload;
    },
    setCurrentZoom: (state, action) => {
      state.zoom.currentZoom = action.payload;
    },
    setCurrentZoomActive: (state, action) => {
      state.zoom.currentZoomActive = action.payload;
    },
    setCurrentGouletZone: (state, action) => {
      state.goulets.currentGouletZone = action.payload;
    },
    setSidebarProductions: (state, action) => {
      state.productions = action.payload;
    },
    toggleProduction: (state) => {
      state.toggleProd = !state.toggleProd;
    },
    toggleConsommation: (state) => {
      state.consommation = !state.consommation;
    },
    setCurrentPointActive: (state, action) => {
      state.pointsIn.currentPointActive = action.payload;
    },
    setCadreActivePointIn: (state, action) => {
      state.pointsIn.cadreActivePoints = action.payload;
    },
    setConsoPolygons: (state, action) => {
      state.pointsIn.consoPolygons = action.payload;
    },
    setFuseauPolygons: (state, action) => {
      state.pointsIn.fuseauPolygons = action.payload;
    },
    setLignesPointIn: (state, action) => {
      state.pointsIn.lignes = action.payload;
    },
    setPostPointIn: (state, action) => {
      state.pointsIn.postPoints = action.payload;
    },
    setIconePointIn: (state, action) => {
      state.pointsIn.iconePoints = action.payload;
    },

    setNumberPointIn: (state, action) => {
      state.pointsIn.number = action.payload;
    },
    setElementHeightPointIn: (state, action) => {
      state.pointsIn.elementHeight = action.payload;
    },
    setNumberActivePointIn: (state, action) => {
      state.pointsIn.numberActive = action.payload;
    },
    setCategoryActivePointIn: (state, action) => {
      state.pointsIn.categoryActive = action.payload;
    },
    setInitStateViewPointIn: (state, action) => {
      state.pointsIn.initStateView = action.payload;
    },
    setAdaptationPointIn: (state, action) => {
      state.pointsIn.adaptation = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTooltip,
  setCurrentYear,
  setCurrentPointActive,
  setLignesPointIn,
  setPostPointIn,
  setIconePointIn,
  setNumberPointIn,
  setElementHeightPointIn,
  setStateClimate,
  toggleModalLegend,
  toggleModalSimulator,
  setSegments,
  setSites,
  setGrid,
  setGoulets,
  setGouletSymbols,
  setGouletIcons,
  setPathIcons,
  setZoomPoints,
  setCurrentGouletZone,
  setCurrentZoom,
  setCurrentZoomActive,
  setSidebarProductions,
  toggleConsommation,
  toggleProduction,
  toggleFlux,
  setNumberActivePointIn,
  setInitStateViewPointIn,
  setCategoryActivePointIn,
  setCadreActivePointIn,
  setAdaptationPointIn,
  setConsoPolygons,
  setFuseauPolygons,
} = mainSlice.actions;

export const selectTooltip = (state: RootState) => state.main.tooltip;

export const selectStateClimate = (state: RootState) => state.main.stateClimate;
export const selectCurrentYear = (state: RootState) => state.main.currentYear;
export const selectFlux = (state: RootState) => state.main.toggleFlux;

export const selectModalLegend = (state: RootState) =>
  state.main.toggleModalActive.legend;

export const selectModalSimulator = (state: RootState) =>
  state.main.toggleModalActive.simulator;

export const selectSegments = (state: RootState) => state.main.segments;
export const selectSites = (state: RootState) => state.main.sites;
export const selectGoulets = (state: RootState) => state.main.goulets.polygons;
export const selectGouletSymbols = (state: RootState) =>
  state.main.goulets.symbols;
export const selectCurrentGouletZone = (state: RootState) =>
  state.main.goulets.currentGouletZone;
export const selectGouletsByYear = createSelector(
  [
    selectGoulets,
    selectCurrentYear,
    selectStateClimate,
    selectCurrentGouletZone,
  ],
  (goulets, currentYear, climate, gouletId) => {
    if (CURRENT_YEAR.YEAR_2024 === currentYear) {
      return [];
    }
    return gouletId === ''
      ? goulets
      : goulets.filter((g: any) => {
          return g.properties.zone === gouletId;
        });
  },
);
const selectGouletIcons = (state: RootState) => state.main.goulets.icons;
export const selectGouletIconsByYear = createSelector(
  [selectGouletIcons, selectCurrentYear, selectStateClimate],
  (icons, currentYear, climate) => {
    if (
      CURRENT_YEAR.YEAR_2024 === currentYear ||
      CURRENT_YEAR.YEAR_2035 === currentYear ||
      CURRENT_YEAR.YEAR_2040 === currentYear
    ) {
      return [];
    }
    return icons;
  },
);

const selectPathGoulets = (state: RootState) => state.main.goulets.paths;
export const selectGouletsPathByYear = createSelector(
  [selectPathGoulets, selectCurrentYear],
  (paths, currentYear) => {
    if (CURRENT_YEAR.YEAR_2024 === currentYear) {
      return [];
    }
    return paths;
  },
);

export const selectGouletGrid = (state: RootState) => state.main.goulets.grid;

const selectZoomPoints = (state: RootState) => state.main.zoom.zoomPoints;
export const selectZoomPointsByYear = createSelector(
  [selectZoomPoints, selectCurrentYear],
  (goulets, currentYear) => {
    if (CURRENT_YEAR.YEAR_2030 === currentYear) {
      return [];
    }
    return goulets;
  },
);

export const selectCurrentZoom = (state: RootState) =>
  state.main.zoom.currentZoom;
export const selectCurrentZoomActive = (state: RootState) =>
  state.main.zoom.currentZoomActive;
export const selectSidebarProductions = (state: RootState) =>
  state.main.productions;

export const selectToggleProduction = (state: RootState) =>
  state.main.toggleProd;

export const selectToggleConsommation = (state: RootState) =>
  state.main.consommation;

export const selectCurrentPointActive = (state: RootState) =>
  state.main.pointsIn.currentPointActive;

export const selectLignesPointIn = (state: RootState) =>
  state.main.pointsIn.lignes;

export const selectPostPointIn = (state: RootState) =>
  state.main.pointsIn.postPoints;



export const selectIconePointIn = (state: RootState) =>
  state.main.pointsIn.iconePoints;

export const selectNumberPointIn = (state: RootState) =>
  state.main.pointsIn.number;

export const selectConsoPolygons = (state: RootState) =>
  state.main.pointsIn.consoPolygons;
export const selectFuseauPolygons = (state: RootState) =>
  state.main.pointsIn.fuseauPolygons;

export const selectElementHeightPointIn = (state: RootState) =>
  state.main.pointsIn.elementHeight;

export const selectNumberActivePointIn = (state: RootState) =>
  state.main.pointsIn.numberActive;

export const selectCategoryActivePointIn = (state: RootState) =>
  state.main.pointsIn.categoryActive;

export const selectPostPointInByYearAndClick = createSelector(
  [selectPostPointIn, selectCurrentYear, selectCategoryActivePointIn],
  (points, currentYear, categoryActive) => {
    return points.filter((item) => item.properties.ANNEE === CURRENT_YEAR_VALUES[currentYear as keyof typeof CURRENT_YEAR_VALUES]);
  },
);

export const selectInitStateViewPointIn = (state: RootState) =>
  state.main.pointsIn.initStateView;

export const selectCadreActivePointIn = (state: RootState) =>
  state.main.pointsIn.cadreActivePoints;

export const selectAdaptationPointIn = (state: RootState) =>
  state.main.pointsIn.adaptation;

export default mainSlice.reducer;
