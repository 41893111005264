import { useAppSelector } from '../../../store/hooks';
import {
  selectCurrentYear,
  selectPostPointInByYearAndClick,
} from '../../../store/mainReducer';

import { TextLayer, IconLayer } from '@deck.gl/layers/typed';

import Poste225 from '../../../assets/images/points/Poste225.svg';
import Poste400 from '../../../assets/images/points/Poste400.svg';

import Poste225_zoom from '../../../assets/images/points/Poste225_zoom.svg';
import Poste400_zoom from '../../../assets/images/points/Poste400_zoom.svg';
import Poste225_01 from '../../../assets/images/points/Poste225_01.svg';
import Poste_P2 from '../../../assets/images/points/Poste_P2.svg';

const getIconeByType = (type: string, year: string): any => {
  if (year === 'YEAR_2024') {
    switch (type) {
      case '400':
        return {
          url: Poste400,
          width: 9,
          height: 9,
        };
      case '225':
        return {
          url: Poste225,
          width: 5,
          height: 5,
        };
    }
  } else {
    switch (type) {
      case '400':
        return {
          url: Poste225_zoom,
          width: 14,
          height: 14,
        };
      case '225':
        return {
          url: Poste225_zoom,
          width: 10,
          height: 10,
        };
      case 'transfo':
        return {
          url: Poste225_01,
          width: 10,
          height: 10,
        };
        // case '400-p2':
        //   return {
        //     url: Poste_P2,
        //     width: 14,
        //     height: 14,
        //   };
    }
  }
};

const usePostPointLayer = () => {
  const postPointIn = useAppSelector(selectPostPointInByYearAndClick);
  const currentYear = useAppSelector(selectCurrentYear);

  const layerLignePoint = new TextLayer({
    id: 'post-point-layer',
    data: postPointIn.filter(
      (item) =>
        item.properties.STYLE === 'avec etiquette' &&
        (item.properties.SOUS_TYPE === '400' ||
          item.properties.SOUS_TYPE === '225'),
    ),
    getText: (d: any) => d.properties.IDR_SITE,
    getPosition: (d) => d.position as any,
    getAlignmentBaseline: 'center',
    getColor: [255, 255, 255],
    getBackgroundColor: (d: any) =>
      d.properties.STYLE === 'avec etiquette'
        ? [255, 255, 255, 30]
        : [255, 255, 255, 0],
    backgroundPadding: [4, 4, 4, 4],
    pickable: true,
    getSize: 12,
    fontWeight: 'bold',
    getTextAnchor: 'middle',
    background: true,
    getPixelOffset: [30, -20],
    characterSet: 'auto',
    outlineWidth: 4,
    outlineColor: [255, 255, 255],
    getBorderColor: [255, 255, 255],
    getBorderWidth: 0,
    fontFamily: 'Nunito Sans',
    billboard: true,
    visible: true,
  });

  const layer = new IconLayer({
    id: 'icon-layer-1',
    data: postPointIn,
    getIcon: (d: any) => getIconeByType(d.properties.SOUS_TYPE, currentYear),
    getSize: (d: any) =>
      getIconeByType(d.properties.SOUS_TYPE, currentYear).height,
    getPosition: (d: any) => {
      return d.position;
    },
    getColor: [255, 255, 0],
    getPixelOffset: [0, 0],
    pickable: true,
    visible: true,
  });

  const transfoePoint = new TextLayer({
    id: 'transfo-point-layer',
    data: postPointIn.filter(
      (item) =>
        item.properties.STYLE === 'avec etiquette' &&
        item.properties.SOUS_TYPE === 'transfo',
    ),
    getText: (d: any) => d.properties.IDR_SITE,
    getPosition: (d) => d.position as any,
    getAlignmentBaseline: 'center',
    getColor: [83, 222, 209],
    getBackgroundColor: (d: any) =>
      d.properties.STYLE === 'avec etiquette'
        ? [83, 222, 209, 30]
        : [83, 222, 209, 0],
    backgroundPadding: [4, 4, 4, 4],
    pickable: true,
    getSize: 12,
    fontWeight: 'bold',
    getTextAnchor: 'middle',
    background: true,
    getPixelOffset: [-55, 0],
    characterSet: 'auto',
    outlineWidth: 4,
    outlineColor: [83, 222, 209],
    getBorderColor: [83, 222, 209],
    getBorderWidth: 0,
    fontFamily: 'Nunito Sans',
    billboard: true,
    visible: true,
  });

  const p2Point = new TextLayer({
    id: 'p2-point-layer',
    data: postPointIn.filter(
      (item) =>
        item.properties.STYLE === 'avec etiquette' &&
        item.properties.SOUS_TYPE === '400-p2',
    ),
    getText: (d: any) => d.properties.IDR_SITE,
    getPosition: (d) => d.position as any,
    getAlignmentBaseline: 'center',
    getColor: [39,39,39],
    getBackgroundColor: (d: any) =>
      d.properties.STYLE === 'avec etiquette'
        ? [39,39,39, 60]
        : [39,39,39, 0],
    backgroundPadding: [4, 4, 4, 4],
    pickable: true,
    getSize: 12,
    fontWeight: 'bold',
    getTextAnchor: 'middle',
    background: true,
    getPixelOffset: [-55, 0],
    characterSet: 'auto',
    outlineWidth: 4,
    outlineColor: [39,39,39],
    getBorderColor: [39,39,39],
    getBorderWidth: 0,
    fontFamily: 'Nunito Sans',
    billboard: true,
    visible: true,
  });

  return [transfoePoint, layerLignePoint, layer];
};

export default usePostPointLayer;
