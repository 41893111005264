import React, { useCallback, useState } from 'react';
import DeckGL from '@deck.gl/react';
import Map, { ViewState } from 'react-map-gl';
import MapStyle from '../../assets/map-styles/interest-pt-map-style.json';
import { fromJS } from 'immutable';

import styled from 'styled-components';
import { FlyToInterpolator } from '@deck.gl/core';
import RangeSlider from 'react-bootstrap-range-slider';
import { ZoomPointFeature } from '../../models/ZoomFeature';
import RightPointComponent from './RightPointComponent';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import ContentPointInComponent from './ContentPointInComponent';
import {
  selectAdaptationPointIn,
  selectCategoryActivePointIn,
  selectCurrentPointActive,
  selectCurrentYear,
  selectInitStateViewPointIn,
  setCadreActivePointIn,
  setCategoryActivePointIn,
  setConsoPolygons,
  setFuseauPolygons,
  setCurrentYear,
  setElementHeightPointIn,
  setIconePointIn,
  setInitStateViewPointIn,
  setLignesPointIn,
  setPostPointIn,
  setCurrentZoom,
} from '../../store/mainReducer';

import useLignesPointLayer from '../layers/poinIn/useLignesPointLayer';
import usePostPointLayer from '../layers/poinIn/usePostPointLayer';
import useIconesPointLayer from '../layers/poinIn/useIconesPointLayer';
import { MAPBOX_ACCESS_TOKEN } from '../../utils/constants';
import useCadrePointInLayer from '../layers/poinIn/useCadrePointInLayer';

import ItemLegendPoint from './ItemLegendPoint';
import ZoomSwitcher from './ZoomSwitcher';

import {
  getIconesPointsIn,
  getLignesPoints,
  getCadrePointsIn,
  getPostsPointsIn,
  getConsoPolygons,
  getInitStatView,
  getFuseauPolygons,
} from '../../services';
import { splitYear } from '../../utils';
import { YEARS_A_REF } from '../../utils/constants';
import useConsoLayer from '../layers/poinIn/useConsoLayer';
import useFuseauLayer from '../layers/poinIn/useFuseauLayer';
import { CURRENT_YEAR_VALUES } from '../../utils/enums';

interface ModalProps {
  currentZoom: ZoomPointFeature;
  handleClose: () => void;
}

const StyledDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1500;
`;

const VIEW_STAT_ADPT_2030 = {
  zoom: 8.6,
  longitude: 5.005176863108805,
  latitude: 43.6461639430252,
};

const PointInterestMap: React.FC<ModalProps> = ({
  currentZoom,
  handleClose,
}) => {
  const dispatch = useAppDispatch();
  const currentYear = useAppSelector(selectCurrentYear);
  const currentPointActive = useAppSelector(selectCurrentPointActive);
  const initStateViewPointIn = useAppSelector(selectInitStateViewPointIn);
  const adaptationPointIn = useAppSelector(selectAdaptationPointIn);
  const categoryActivePointIn = useAppSelector(selectCategoryActivePointIn);

  console.log(
    'adaptationPointIn && currentYear',
    categoryActivePointIn,
    adaptationPointIn,
    currentYear,
    initStateViewPointIn,
  );

  const [viewState, setViewState] = useState(initStateViewPointIn);

  const elementRef = React.useRef<any>(null); // Create a ref to the element

  const handleCloseModal = (): void => {
    handleClose();
  };

  const lignesPointLayer = useLignesPointLayer();
  const consoLayer = useConsoLayer();
  const fuseauLayer = useFuseauLayer();
  const cadrePointInLayer = useCadrePointInLayer();
  const iconesPointLayer = useIconesPointLayer();
  const postPointLayer = usePostPointLayer();

  // ADD Layers
  const layers: any = [
    consoLayer,
    lignesPointLayer,
    cadrePointInLayer,
    iconesPointLayer,
    fuseauLayer,
    postPointLayer, 
  ];

  const setUpdateStateView = (year: string, newZoom: number) => {
    let longitude = initStateViewPointIn.longitude;
    let latitude = initStateViewPointIn.latitude;

    const state = getInitStatView(currentPointActive, year);
    longitude = state.longitude;
    latitude = state.latitude;

    setViewState((prevState: any) => ({
      ...prevState,
      zoom: newZoom,
      latitude,
      longitude,
      transitionDuration: 600,
      // transitionInterpolator: new FlyToInterpolator(),
    }));

    dispatch(setInitStateViewPointIn(state));
  };

  const setUpdateDataByZoom = (year: string) => {
    dispatch(setCategoryActivePointIn('pas de click'));
    dispatch(setCurrentYear(`YEAR_${year}`));
    const data_ligne = getLignesPoints(currentPointActive, year);
    dispatch(setLignesPointIn(data_ligne));

    const data_icones = getIconesPointsIn(currentPointActive, year);
    dispatch(setIconePointIn(data_icones));

    const data_post = getPostsPointsIn(currentPointActive, year);
    dispatch(setPostPointIn(data_post));

    const data_cadre = getCadrePointsIn(currentPointActive);
    dispatch(setCadreActivePointIn(data_cadre));

    const conso_polygons = getConsoPolygons();
    dispatch(setConsoPolygons(conso_polygons));

    const fuseau_polygons = getFuseauPolygons();
    dispatch(setFuseauPolygons(fuseau_polygons));
  };

  React.useEffect(() => {
    setUpdateDataByZoom(splitYear(currentYear).toString());
    setUpdateStateView(splitYear(currentYear).toString(), viewState.zoom);

    if (elementRef.current) {
      // Access the height of the element
      const elementHeight = elementRef.current.clientHeight;
      dispatch(setElementHeightPointIn(elementHeight)); // Set the height in state
    }
  }, [dispatch]); // Empty dependency array to run on mount

  // SLIDER BY ZOOM
  const handleZoomChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newZoom = parseFloat(event.target.value);
      let year = splitYear(currentYear).toString();

      // UPDATE INIT VIEW
      if (newZoom === 8) {
        year = `2024`;
      } else if (newZoom === 9.8) {
        year = `2030`;
      }

      setUpdateStateView(year, newZoom);

      setUpdateDataByZoom(year);
    },
    [],
  );

  return (
    <StyledDiv>
      <div className="container-fluid">
        <div className="row vh-100-md p-10" id="content">
          <div
            className="col-12 order-1 order-md-0 col-md-2 d-none d-md-block sidebar"
            id="left-area"
          >
            <div
              className="row p-2 px-3 pr-3"
              style={{ position: 'relative', zIndex: 1500 }}
            >
              <div className="col-12 mt-2">
                <h5 className="mb-0">{'Mix énergétique :'}</h5>
              </div>
              <div className="btn-year">
                <div className="slider">
                  <div className="d-flex justify-content-between">
                    {YEARS_A_REF.map((item: any, index: number) => {
                      return (
                        <small key={index} className="text-muted">
                          {item}
                        </small>
                      );
                    })}
                  </div>
                  <RangeSlider
                    min={8}
                    max={9.8}
                    step={1.8}
                    value={viewState.zoom}
                    onChange={handleZoomChange}
                    tooltip="off" // Disable the built-in tooltip, you can enable if you prefer
                  />
                </div>
              </div>
              {currentYear !== `YEAR_2024` && (
                <>
                  <div className="col-12 mt-2 mb-1">
                    <h5 className="mb-0">{'Réseau :'}</h5>
                  </div>
                  <ZoomSwitcher />
                </>
              )}
              <ItemLegendPoint />
            </div>
          </div>
          <div
            className="col-12 order-0 order-md-1 col-md-8 vh-md-100 vh-sm-100 pe-2 ps-2 shadow-sm"
            id="center-area"
            ref={elementRef}
            style={{ height: '100vh', position: 'relative' }}
          >
            {!adaptationPointIn ? (
              <DeckGL
                layers={layers}
                initialViewState={viewState}
                controller={true}
                onViewStateChange={({ viewState }) => setViewState(viewState)}
              >
                <Map
                  reuseMaps
                  mapStyle={fromJS(MapStyle) as any}
                  mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                  dragRotate={false}
                  dragPan={false}
                  touchZoomRotate={false}
                  doubleClickZoom={false}
                  scrollZoom={false}
                />
                <ContentPointInComponent handleClose={handleCloseModal} />
              </DeckGL>
            ) : (
              <DeckGL
                layers={layers}
                initialViewState={VIEW_STAT_ADPT_2030}
                controller={true}
              >
                <Map
                  reuseMaps
                  mapStyle={fromJS(MapStyle) as any}
                  mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                  dragRotate={false}
                  dragPan={false}
                  touchZoomRotate={false}
                  doubleClickZoom={false}
                  scrollZoom={false}
                />
                <ContentPointInComponent handleClose={handleCloseModal} />
              </DeckGL>
            )}
          </div>
          <div
            className="col-12 order-2 order-md-2 col-md-2 d-none d-md-block sidebar"
            id="right-area"
          >
            <RightPointComponent />
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

export default PointInterestMap;
