import { useAppSelector } from '../../../store/hooks';
import {
  selectCategoryActivePointIn,
  selectCurrentYear,
  selectIconePointIn,
} from '../../../store/mainReducer';
import { IconLayer, TextLayer } from '@deck.gl/layers/typed';
import Co2h2 from '../../../assets/images/points/co2h2.svg';
import Industrie from '../../../assets/images/points/industrie.svg';
import Notification from '../../../assets/images/points/notification.svg';
import Roquerousse from '../../../assets/images/points/Roquerousse.svg';

const useIconesPointLayer = () => {
  const iconesPointIn = useAppSelector(selectIconePointIn);
  const activePointIn = useAppSelector(selectCategoryActivePointIn);
  const currentYear = useAppSelector(selectCurrentYear);
// console.log('iconesPointIn', iconesPointIn);

  const co2h2Icone = new IconLayer({
    id: 'icones-co2h2-layer',
    data: iconesPointIn.filter(
      (item: any) => item.properties.SOUS_TYPE === 'co2h2',
    ),
    getIcon: (d: any) => ({
      url: Co2h2,
      width: 51,
      height: 50,
    }),
    getPosition: (d: any) => {
      return d.position;
    },
    getSize: (d) => 50,
    pickable: true,
    visible: activePointIn === 'co2h2' || activePointIn === 'pas de click',
  });

  const co2h2Name = new TextLayer({
    id: 'name-layer-1',
    data: iconesPointIn.filter(
      (item: any) => item.properties.SOUS_TYPE === 'co2h2',
    ),
    getText: (d: any) => `${d.variable}`,
    getPosition: (d: any) => d.position as any,
    getAlignmentBaseline: 'center',
    getSize: 11,
    getColor: (d: any) => d.color,
    backgroundPadding: [4, 2, 4, 2],
    pickable: true,
    getTextAnchor: 'start',
    background: false,
    getPixelOffset: (d: any) => [15, -22],
    characterSet: 'auto',
    outlineWidth: 10,
    maxWidth: 30.0,
    wordBreak: 'break-word',
    getBorderWidth: 0,
    fontFamily: 'Nunito Sans',
    billboard: true,
    visible: activePointIn === 'co2h2' || activePointIn === 'pas de click',
  });

  const cercleCo2h2Icone = new IconLayer({
    id: 'cercle-co2h2-layer',
    data: iconesPointIn.filter(
      (item: any) => item.properties.SOUS_TYPE === 'co2h2',
    ),
    getIcon: (d: any) => ({
      url: Notification,
      width: 25,
      height: 17,
    }),
    getPosition: (d: any) => {
      return d.position;
    },
    getSize: (d) => 17,
    getPixelOffset: (d: any) => [18, -22],
    pickable: true,
    visible: activePointIn === 'co2h2' || activePointIn === 'pas de click',
  });

  const industrieIcone = new IconLayer({
    id: 'icones-industrie-layer',
    data: iconesPointIn.filter(
      (item: any) => item.properties.SOUS_TYPE === 'industrie',
    ),
    getIcon: (d: any) => ({
      url: Industrie,
      width: 51,
      height: 50,
    }),
    getPosition: (d: any) => {
      return d.position;
    },
    getSize: (d) => 50,
    pickable: true,
    visible: activePointIn === 'industrie' || activePointIn === 'pas de click',
  });

  const industrieName = new TextLayer({
    id: 'name-layer-2',
    data: iconesPointIn.filter(
      (item: any) => item.properties.SOUS_TYPE === 'industrie',
    ),
    getText: (d: any) => `${d.variable}`,
    getPosition: (d: any) => d.position as any,
    getAlignmentBaseline: 'center',
    getSize: 11,
    getColor: (d: any) => d.color,
    backgroundPadding: [4, 2, 4, 2],
    pickable: true,
    getTextAnchor: 'start',
    background: false,
    getPixelOffset: (d: any) => [15, -22],
    characterSet: 'auto',
    outlineWidth: 10,
    maxWidth: 30.0,
    wordBreak: 'break-word',
    getBorderWidth: 0,
    fontFamily: 'Nunito Sans',
    billboard: true,
    visible: activePointIn === 'industrie' || activePointIn === 'pas de click',
  });

  const cercleindustrieIcone = new IconLayer({
    id: 'cercle-industrie-layer',
    data: iconesPointIn.filter(
      (item: any) => item.properties.SOUS_TYPE === 'industrie',
    ),
    getIcon: (d: any) => ({
      url: Notification,
      width: 25,
      height: 17,
    }),
    getPosition: (d: any) => {
      return d.position;
    },
    getSize: (d) => 17,
    getPixelOffset: (d: any) => [18, -22],
    pickable: true,
    visible: activePointIn === 'industrie' || activePointIn === 'pas de click',
  });


  const transfoIcone = new IconLayer({
    id: 'icones-transfo-layer',
    data: iconesPointIn.filter(
      (item: any) => item.properties.SOUS_TYPE === 'transfo',
    ),
    getIcon: (d: any) => ({
      url: Roquerousse,
      width: 46,
      height: 65,
    }),
    getPosition: (d: any) => {
      return d.position;
    },
    getSize: (d) => 65,
    getPixelOffset: (d: any) => [0, -40],
    pickable: true,
    visible: activePointIn === 'transfo' || activePointIn === 'pas de click',
  });

  return [
    co2h2Icone,
    cercleCo2h2Icone,
    co2h2Name,
    industrieIcone,
    cercleindustrieIcone,
    industrieName,
    transfoIcone,
  ];
};

export default useIconesPointLayer;
