
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  selectToggleConsommation,
  toggleConsommation,
} from '../../../../store/mainReducer';

import Eye from '../../../../assets/images/eye.svg';
import EyeClose from '../../../../assets/images/eye-close.svg';
import Conso from '../../../../assets/images/conso.svg';

const Consommation = () => {
  const dispatch = useAppDispatch();
  const active = useAppSelector(selectToggleConsommation);
  const handleClick = () => {
    dispatch(toggleConsommation());
  };
  return (
    <div className="row  align-items-center">
      <div className="col-12">
        <div className="row">
          <div
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
            className="col-12"
          >
            <div className="row pt-3 pb-2">
              <div className="col-10 fs-6">{'Consommation'}</div>
              <div className="col-2 text-end">
                <img src={active ? Eye : EyeClose} alt="Eye" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-12">
          <img src={Conso} alt="Conso" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Consommation;
