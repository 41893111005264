import { PolygonLayer } from '@deck.gl/layers/typed';
import { PathStyleExtension } from '@deck.gl/extensions/typed';
import { useAppSelector } from '../../../store/hooks';
import {
  selectCadreActivePointIn,
  selectCurrentYear,
} from '../../../store/mainReducer';
import { CURRENT_YEAR_VALUES } from '../../../utils/enums';

const useCadrePointInLayer = () => {
  const cadreActivePointIn = useAppSelector(selectCadreActivePointIn);
  const currentYear = useAppSelector(selectCurrentYear);

  const currentYearValue =
      CURRENT_YEAR_VALUES[currentYear as keyof typeof CURRENT_YEAR_VALUES];

  const goulets = new PolygonLayer({
    id: `cadre`,
    data: cadreActivePointIn.filter((item) => item.properties.ANNEE === currentYearValue),
    getPolygon: (d) => d.position,
    lineWidthUnits: 'pixels',
    stroked: true,
    extruded: false,
    getFillColor: (d) => [255, 0, 0, 0.5],
    getLineColor: (d) => [220, 220, 220],
    filled: true, // Fills the polygon with color
    getLineWidth: 2,
    lineWidthMinPixels: 2,
    lineJointRounded: true,
    pickable: true,
    visible: true,
    // PathStyleExtension specific settings
    getDashArray: () => [4, 6], // Set dash array for the dashed line pattern
    dashJustified: false,
    // dashGapPickable: false,
    extensions: [new PathStyleExtension({ highPrecisionDash: true })],
  });

  return [goulets];
};

export default useCadrePointInLayer;
