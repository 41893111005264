import { PathLayer } from '@deck.gl/layers/typed';
import { useAppSelector } from '../../../store/hooks';
import {
  selectAdaptationPointIn,
  selectCategoryActivePointIn,
  selectCurrentYear,
  selectLignesPointIn,
} from '../../../store/mainReducer';
import { CURRENT_YEAR_VALUES } from '../../../utils/enums';

const useLignesPointLayer = () => {
  const currentYear = useAppSelector(selectCurrentYear);
  const lignesPointIn = useAppSelector(selectLignesPointIn);
  const catActivePointIn = useAppSelector(selectCategoryActivePointIn);
  const adaptationPointIn = useAppSelector(selectAdaptationPointIn);

  const currentYearValue =
    CURRENT_YEAR_VALUES[currentYear as keyof typeof CURRENT_YEAR_VALUES];

  // pas de click 2024
  const paths_no_225_1 = new PathLayer({
    id: 'path-225-1',
    data: lignesPointIn.filter(
      (item: any) =>
        item.properties.ANNEE === currentYearValue &&
        item.properties.STYLE === '225-1' &&
        item.properties.CLICK === 'pas de click' &&
        item.properties.CLICK === catActivePointIn,
    ),
    getPath: (d: any) => d.position,
    getWidth: (d: any) => d.size,
    widthUnits: 'pixels',
    getColor: (d: any) => d.color,
  });

  const paths_no_400_1 = new PathLayer({
    id: 'path-400-1',
    data: lignesPointIn.filter(
      (item: any) =>
        item.properties.ANNEE === currentYearValue &&
        item.properties.STYLE === '400-1' &&
        item.properties.CLICK === 'pas de click' &&
        item.properties.CLICK === catActivePointIn,
    ),
    getPath: (d: any) => d.position,
    getWidth: (d: any) => d.size,
    widthUnits: 'pixels',
    getColor: (d: any) => d.color,
  });

  // 400 / 2024
  const paths_400_400_1 = new PathLayer({
    id: 'path-400-400-1',
    data: lignesPointIn.filter(
      (item: any) =>
        item.properties.ANNEE === currentYearValue &&
        item.properties.STYLE === '400-1' &&
        item.properties.CLICK === '400' &&
        item.properties.CLICK === catActivePointIn,
    ),
    getPath: (d: any) => d.position,
    getWidth: (d: any) => d.size,
    widthUnits: 'pixels',
    getColor: (d: any) => d.color,
  });

  const paths_400_400_2 = new PathLayer({
    id: 'path-400-400-2',
    data: lignesPointIn.filter(
      (item: any) =>
        item.properties.ANNEE === currentYearValue &&
        item.properties.CLICK === catActivePointIn &&
        item.properties.CLICK === '400' &&
        item.properties.STYLE === '400-2',
    ),
    getPath: (d: any) => d.position,
    getWidth: (d: any) => d.position,
    widthUnits: 'pixels',
    getColor: (d: any) => d.color,
  });

  const paths_400_400_2_active = new PathLayer({
    id: 'path-400-2-qqqactive',
    data: lignesPointIn.filter(
      (item: any) =>
        item.properties.ANNEE === currentYearValue &&
        item.properties.CLICK === catActivePointIn &&
        item.properties.CLICK === '400' &&
        item.properties.STYLE === '400-2',
    ),
    getPath: (d: any) => d.position,
    getWidth: (d: any) => 20,
    widthUnits: 'pixels',
    getColor: (d: any) => d.color,

    opacity: 0.1,
  });
  const paths_400_225_1 = new PathLayer({
    id: 'path-400-225-1',
    data: lignesPointIn.filter(
      (item: any) =>
        item.properties.ANNEE === currentYearValue &&
        item.properties.STYLE === '225-3' &&
        item.properties.CLICK === '400' &&
        item.properties.CLICK === catActivePointIn,
    ),
    getPath: (d: any) => d.position,
    getWidth: (d: any) => d.size,
    widthUnits: 'pixels',
    getColor: (d: any) => d.color,
  });

  // 225 / 2024
  const paths_225_400_3 = new PathLayer({
    id: 'paths_225_400_3',
    data: lignesPointIn.filter(
      (item: any) =>
        item.properties.ANNEE === currentYearValue &&
        item.properties.STYLE === '400-3' &&
        item.properties.CLICK === '225' &&
        item.properties.CLICK === catActivePointIn,
    ),
    getPath: (d: any) => d.position,
    getWidth: (d: any) => d.size,
    widthUnits: 'pixels',
    getColor: (d: any) => d.color,
  });

  const paths_225_225_2 = new PathLayer({
    id: 'paths_225_225_2',
    data: lignesPointIn.filter(
      (item: any) =>
        item.properties.ANNEE === currentYearValue &&
        item.properties.STYLE === '225-2' &&
        item.properties.CLICK === '225' &&
        item.properties.CLICK === catActivePointIn,
    ),
    getPath: (d: any) => d.position,
    getWidth: (d: any) => d.size,
    widthUnits: 'pixels',
    getColor: (d: any) => d.color,
  });

  const paths_225_225_2_active = new PathLayer({
    id: 'paths_225_225_2-active',
    data: lignesPointIn.filter(
      (item: any) =>
        item.properties.ANNEE === currentYearValue &&
      item.properties.STYLE === '225-2' &&
        item.properties.CLICK === '225' &&
        item.properties.CLICK === catActivePointIn,
    ),
    getPath: (d: any) => d.position,
    getWidth: (d: any) => 20,
    widthUnits: 'pixels',
    getColor: (d: any) => d.color,
    opacity: 0.1,
  });

  const paths_225_225_1 = new PathLayer({
    id: 'paths_225_225_1',
    data: lignesPointIn.filter(
      (item: any) =>
        item.properties.ANNEE === currentYearValue &&
        item.properties.STYLE === '225-1' &&
        item.properties.CLICK === '225' &&
        item.properties.CLICK === catActivePointIn,
    ),
    getPath: (d: any) => d.position,
    getWidth: (d: any) => d.size,
    widthUnits: 'pixels',
    getColor: (d: any) => d.color,
  });

  // no click / 2030
  const paths_no_400_4 = new PathLayer({
    id: 'paths_no_400_4',
    data: lignesPointIn.filter(
      (item: any) =>
        item.properties.ANNEE === currentYearValue &&
        item.properties.STYLE === '400-4' &&
        item.properties.CLICK === 'pas de click' &&
        item.properties.CLICK === catActivePointIn,
    ),
    getPath: (d: any) => d.position,
    getWidth: (d: any) => d.size,
    widthUnits: 'pixels',
    getColor: (d: any) => d.color,
  });

  const paths_no_400_4_active = new PathLayer({
    id: 'paths_no_400_4_active',
    data: lignesPointIn.filter(
      (item: any) =>
        item.properties.ANNEE === currentYearValue &&
      item.properties.STYLE === '400-4' &&
        item.properties.CLICK === 'pas de click' &&
        item.properties.CLICK === catActivePointIn,
    ),
    getPath: (d: any) => d.position,
    getWidth: (d: any) => 20,
    widthUnits: 'pixels',
    getColor: (d: any) => d.color,
    opacity: 0.1,
  });

  const paths_no_225_4 = new PathLayer({
    id: 'paths_no_225_4',
    data: lignesPointIn.filter(
      (item: any) =>
        item.properties.ANNEE === currentYearValue &&
        item.properties.STYLE === '225-4' &&
        item.properties.CLICK === 'pas de click' &&
        item.properties.CLICK === catActivePointIn,
    ),
    getPath: (d: any) => d.position,
    getWidth: (d: any) => d.size,
    widthUnits: 'pixels',
    getColor: (d: any) => d.color,
  });

  const paths_no_225_4_active = new PathLayer({
    id: 'paths_no_225_4_active',
    data: lignesPointIn.filter(
      (item: any) =>
        item.properties.ANNEE === currentYearValue &&
      item.properties.STYLE === '225-4' &&
        item.properties.CLICK === 'pas de click' &&
        item.properties.CLICK === catActivePointIn,
    ),
    getPath: (d: any) => d.position,
    getWidth: (d: any) => 15,
    widthUnits: 'pixels',
    getColor: (d: any) => d.color,
    opacity: 0.1,
  });


// industrie / 2030
const paths_industrie_225_4 = new PathLayer({
  id: 'paths_industrie_225_4',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
      item.properties.STYLE === '225-4' &&
      item.properties.CLICK === 'industrie' &&
      item.properties.CLICK === catActivePointIn,
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.size,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
});

const paths_industrie_225_4_active = new PathLayer({
  id: 'paths_industrie_225_4_active',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
    item.properties.STYLE === '225-4' &&
      item.properties.CLICK === 'industrie' &&
      item.properties.CLICK === catActivePointIn,
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => 15,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
  opacity: 0.1,
});

const paths_industrie_225_5 = new PathLayer({
  id: 'paths_industrie_225_4',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
      item.properties.STYLE === '225-5' &&
      item.properties.CLICK === 'industrie' &&
      item.properties.CLICK === catActivePointIn,
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.size,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
});
const paths_industrie_400_5 = new PathLayer({
  id: 'paths_industrie_400_5',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
      item.properties.STYLE === '400-5' &&
      item.properties.CLICK === 'industrie' &&
      item.properties.CLICK === catActivePointIn,
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.size,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
});

// co2h2 / 2030
const paths_co2h2_225_4 = new PathLayer({
  id: 'paths_co2h2_225_4',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
      item.properties.STYLE === '225-4' &&
      item.properties.CLICK === 'co2h2' &&
      item.properties.CLICK === catActivePointIn,
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.size,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
});

const paths_co2h2_225_4_active = new PathLayer({
  id: 'paths_co2h2_225_4_active',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
    item.properties.STYLE === '225-4' &&
      item.properties.CLICK === 'co2h2' &&
      item.properties.CLICK === catActivePointIn,
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => 15,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
  opacity: 0.1,
});

const paths_co2h2_225_5 = new PathLayer({
  id: 'paths_co2h2_225_5',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
      item.properties.STYLE === '225-5' &&
      item.properties.CLICK === 'co2h2' &&
      item.properties.CLICK === catActivePointIn,
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.size,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
});

const paths_co2h2_400_5 = new PathLayer({
  id: 'paths_co2h2_400_5',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
      item.properties.STYLE === '400-5' &&
      item.properties.CLICK === 'co2h2' &&
      item.properties.CLICK === catActivePointIn,
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.size,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
});

// electrification / 2030
const paths_electrification_225_4 = new PathLayer({
  id: 'paths_electrification_225_4',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
      item.properties.STYLE === '225-4' &&
      item.properties.CLICK === 'electrification' &&
      item.properties.CLICK === catActivePointIn,
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.size,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
});

const paths_electrification_225_4_active = new PathLayer({
  id: 'paths_co2h2_225_4_active',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
    item.properties.STYLE === '225-4' &&
      item.properties.CLICK === 'electrification' &&
      item.properties.CLICK === catActivePointIn,
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => 15,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
  opacity: 0.1,
});

const paths_electrification_225_5 = new PathLayer({
  id: 'paths_electrification_225_5',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
      item.properties.STYLE === '225-5' &&
      item.properties.CLICK === 'electrification' &&
      item.properties.CLICK === catActivePointIn,
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.size,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
});

const paths_electrification_400_5 = new PathLayer({
  id: 'paths_electrification_400_5',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
      item.properties.STYLE === '400-5' &&
      item.properties.CLICK === 'electrification' &&
      item.properties.CLICK === catActivePointIn,
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.size,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
});

// pas de click / 2030 - adaptation
const paths_adapt_no_400_1 = new PathLayer({
  id: 'paths_adapt_no_400_1',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
      item.properties.STYLE === '440-1' &&
      item.properties.CLICK === 'pas de click' &&
      catActivePointIn === 'pas de click',
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.size,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
});

const paths_adapt_no_400_6 = new PathLayer({
  id: 'paths_adapt_no_400_6',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
    catActivePointIn === 'pas de click' &&
      item.properties.CLICK === 'pas de click' &&
      item.properties.STYLE === '400-6',
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.position,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,

  opacity: 1,
});

const paths_adapt_no_400_6_active = new PathLayer({
  id: 'paths_adapt_no_400_6_activee',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
      item.properties.CLICK === catActivePointIn &&
      catActivePointIn === 'pas de click' &&
      item.properties.STYLE === '400-6',
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => 20,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,

  opacity: 0.1,
});

// 400 - palier 1 / 2030 - adaptation
const paths_adapt_p1_400_1 = new PathLayer({
  id: 'paths_adapt_p1_400_1',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
      item.properties.STYLE === '400-1' &&
      item.properties.CLICK === '400 - palier 1' &&
      item.properties.CLICK === catActivePointIn,
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.size,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
});

const paths_adapt_p1_400_6 = new PathLayer({
  id: 'paths_adapt_p1_400_6',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
    item.properties.CLICK === '400 - palier 1' &&
      catActivePointIn === '400 - palier 1' &&
      item.properties.STYLE === '400-6',
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.position,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,

  opacity: 1,
});

const paths_adapt_p1_400_6_active = new PathLayer({
  id: 'paths_adapt_p1_400_6_active',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
    item.properties.CLICK === '400 - palier 1' &&
    catActivePointIn === '400 - palier 1' &&
      item.properties.STYLE === '400-6',
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => 20,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,

  opacity: 0.1,
});

const paths_adapt_p1_400_4 = new PathLayer({
  id: 'paths_adapt_p1_400_4',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
      item.properties.STYLE === '400-4' &&
      item.properties.CLICK === '400 - palier 1' &&
      item.properties.CLICK === catActivePointIn,
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.size,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
});

const paths_adapt_p1_400_4_active = new PathLayer({
  id: 'paths_adapt_p1_400_4_active',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
    item.properties.STYLE === '400-4' &&
      item.properties.CLICK === '400 - palier 1' &&
      item.properties.CLICK === catActivePointIn,
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => 20,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
  opacity: 0.1,
});


// 400 - palier 2 / 2030 - adaptation
const paths_adapt_p2_400_1 = new PathLayer({
  id: 'paths_adapt_p2_400_1',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
      item.properties.STYLE === '400-1' &&
      item.properties.CLICK === '400 - palier 2' &&
      item.properties.CLICK === catActivePointIn,
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.size,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
});

const paths_adapt_p2_400_7 = new PathLayer({
  id: 'paths_adapt_p2_400_7',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
    item.properties.CLICK === '400 - palier 2' &&
      item.properties.CLICK === catActivePointIn &&
      item.properties.STYLE === '400-7',
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.position,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,

  opacity: 1,
});

const paths_adapt_p2_400_7_active = new PathLayer({
  id: 'paths_adapt_p2_400_7_active',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
      item.properties.CLICK === catActivePointIn &&
      item.properties.CLICK === '400 - palier 2' &&
      item.properties.STYLE === '400-7',
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => 20,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,

  opacity: 0.1,
});


// transfo / 2030 - adaptation
const paths_adapt_transfo_400_1 = new PathLayer({
  id: 'paths_adapt_transfo_400_1',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
      item.properties.STYLE === '400-1' &&
      item.properties.CLICK === 'transfo' &&
      item.properties.CLICK === catActivePointIn,
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.size,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
});

const paths_adapt_transfo_400_4 = new PathLayer({
  id: 'paths_adapt_transfo_400_4',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
      item.properties.STYLE === '400-4' &&
      item.properties.CLICK === 'transfo' &&
      item.properties.CLICK === catActivePointIn,
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.size,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
});

const paths_transfo_400_4_active = new PathLayer({
  id: 'paths_transfo_400_4_active',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
    item.properties.STYLE === '400-4' &&
      item.properties.CLICK === 'transfo' &&
      item.properties.CLICK === catActivePointIn,
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => 20,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,
  opacity: 0.1,
});

const paths_adapt_transfo_400_6 = new PathLayer({
  id: 'paths_adapt_transfo_400_6',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
    item.properties.CLICK === 'transfo' &&
      catActivePointIn === 'transfo' &&
      item.properties.STYLE === '400-6',
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.position,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,

  opacity: 1,
});

const paths_adapt_transfo_400_6_active = new PathLayer({
  id: 'paths_adapt_transfo_400_6_active',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
    item.properties.CLICK === 'transfo' &&
    catActivePointIn === 'transfo' &&
      item.properties.STYLE === '400-6',
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => 20,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,

  opacity: 0.1,
});

const paths_adapt_transfo_400_7 = new PathLayer({
  id: 'paths_adapt_transfo_400_7',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
    item.properties.CLICK === 'transfo' &&
      item.properties.CLICK === catActivePointIn &&
      item.properties.STYLE === '400-7',
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => d.position,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,

  opacity: 1,
});

const paths_adapt_transfo_400_7_active = new PathLayer({
  id: 'paths_adapt_transfo_400_7_active',
  data: lignesPointIn.filter(
    (item: any) =>
      item.properties.ANNEE === currentYearValue &&
      item.properties.CLICK === catActivePointIn &&
      item.properties.CLICK === 'transfo' &&
      item.properties.STYLE === '400-7',
  ),
  getPath: (d: any) => d.position,
  getWidth: (d: any) => 20,
  widthUnits: 'pixels',
  getColor: (d: any) => d.color,

  opacity: 0.1,
});


  return [
    paths_no_225_1,
    paths_no_400_1,

    paths_400_400_1,
    paths_400_400_2,
    paths_400_400_2_active,
    paths_400_225_1,

    paths_225_400_3,
    paths_225_225_2,
    paths_225_225_2_active,
    paths_225_225_1,

    paths_no_400_4,
    paths_no_400_4_active,
    paths_no_225_4,
    paths_no_225_4_active,

    paths_industrie_225_4,
    paths_industrie_225_4_active,
    paths_industrie_225_5,
    paths_industrie_400_5,

    paths_co2h2_225_4,
    paths_co2h2_225_4_active,
    paths_co2h2_225_5,
    paths_co2h2_400_5,

    paths_electrification_225_4,
    paths_electrification_225_4_active,
    paths_electrification_225_5,
    paths_electrification_400_5,

    paths_adapt_no_400_1,
    paths_adapt_no_400_6,
    paths_adapt_no_400_6_active,

    paths_adapt_p1_400_1,
    paths_adapt_p1_400_6,
    paths_adapt_p1_400_6_active,
    paths_adapt_p1_400_4,
    paths_adapt_p1_400_4_active,

    paths_adapt_p2_400_1,
    paths_adapt_p2_400_7,
    paths_adapt_p2_400_7_active,

    paths_adapt_transfo_400_1,
    paths_adapt_transfo_400_7,
    paths_adapt_transfo_400_7_active,
    paths_adapt_transfo_400_4,
    paths_transfo_400_4_active,
    paths_adapt_transfo_400_6,
    paths_adapt_transfo_400_6_active
  ];
};

export default useLignesPointLayer;
