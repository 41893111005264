import { useAppSelector } from '../../../store/hooks';
import {
  selectSegments,
} from '../../../store/mainReducer';

const RightAreaComponent = () => {

  const visibleSegments = useAppSelector(selectSegments);

  return (
    <div className='right-area'>
      {/* {visibleSegments[0] && (
        <div className="row p-2">
          <div className="col-12 text-center pt-3">
            {visibleSegments[0].title && <h5>{visibleSegments[0].title}</h5>}
          </div>
          <hr />
          <div className="col-12">
            <div
              className="p-2 text"
              dangerouslySetInnerHTML={{
                __html: visibleSegments[0].text,
              }}
            />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default RightAreaComponent;
