import { PolygonLayer } from '@deck.gl/layers/typed';
import { useAppSelector } from '../../../store/hooks';
import {
  selectCategoryActivePointIn,
  selectConsoPolygons,
  selectCurrentPointActive,
  selectCurrentYear,
} from '../../../store/mainReducer';
import { CURRENT_YEAR_VALUES } from '../../../utils/enums';

const useConsoLayer = () => {
  const currentPointActive = useAppSelector(selectCurrentPointActive);
  const currentYear = useAppSelector(selectCurrentYear);
  const categoryActivePointIn = useAppSelector(selectCategoryActivePointIn);
  const consoPolygons = useAppSelector(selectConsoPolygons);

  const goulets = new PolygonLayer({
    id: `goulets`,
    data: consoPolygons.filter((conso: any) => {
      return conso.properties.ANNEE === CURRENT_YEAR_VALUES[currentYear as keyof typeof CURRENT_YEAR_VALUES] && conso.properties.ZONE === currentPointActive && conso.properties.CLICK === categoryActivePointIn;
    }),
    getPolygon: (d) => d.position,
    stroked: false,
    extruded: false,
    getFillColor: (d) => [204, 117, 255],
    filled: true, // Fills the polygon with color
    lineJointRounded: true,
    pickable: true,
    opacity: 0.2
  });

  return [goulets];
};

export default useConsoLayer;
