export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoidGFib3VhbWluZSIsImEiOiJjbHJ0Y2llbW8wMG1pMmtvNmpxajlmZjFxIn0.rxU8U4iQ3JmsRZ9dfXnfeg';
export const LOOP_TRIPS_LENGTH = 1000;

export const LINE_BY_YEARS: Record<string, number> = {
  YEAR_2024: 2024,
  YEAR_2030: 2030,
  YEAR_2035: 2035,
};

export const SWITSH_BY_YEAR: Record<number, string> = {
  2030: '400_AREF_2030_V3_WGS84',
  2035: '400_AREF_2035_V3_WGS84',
  2040: '400_AREF_2040_V3_WGS84',
};

export const NETWORK_LABELS = [
  { name: 'Minimum prévu', value: '0', checked: false },
  { name: 'Réseau cible', value: '1', checked: false },
];

export const SEASON_LABELS = [
  { name: 'été', value: 'E', disabled: false },
  { name: 'Hiver', value: 'H', disabled: false },
];

export const TIME_INTERVAL_LABELS = [
  { name: 'Jour', value: 'J', disabled: false },
  { name: 'Nuit', value: 'N', disabled: false },
];

export const WIND_INTERVAL_LABELS = [
  { name: 'Sans vent', value: 'C', disabled: false },
  { name: 'Venteuse', value: 'V', disabled: false },
];

export const WEATHER_TREND_LABELS = [
  { name: 'Ensoleillée', value: 'S', disabled: false },
  { name: 'Nuageuse', value: 'C', disabled: false },
];

export const METEO_REF_B_LABELS = [
  {
    name: 'Journée d’été',
    value: 'EJCS',
    disabled: false,
  },
  { name: 'Nuit d’hiver', value: 'HNVC', disabled: false },
];

export const YEARS_A_REF = [2024, 2030];
export const YEARS_B_REF = [2024, 2030, 2035, 2040];
export const SENARIOS = ['A-REF', 'B-REF'];
